<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="event_data"
      sort-by="event_date"
      class="elevation-3 ma-2 row-pointer"
      :loading="loading"
      @click:row="handleClick"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Bootcamp Events</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createEvent">Create Event</v-btn>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.event_date="{ item }">
        {{ formatDate(item.event_date) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.v-text-field {
  max-width: 400px;
}
</style>

<script>
import gql from "graphql-tag";
import dayjs from "dayjs";

export default {
  name: "EventList",

  data: () => ({
    loading: false,
    event_data: [],
    headers: [
      {
        text: "Event Date",
        value: "event_date",
      },
      { text: "Number of Agents", value: "agents_cnt" },
      { text: "Capacity", value: "capacity" },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    handleClick(event) {
      this.$router.push(`/admin/events/${event.id}`);
    },
    createEvent() {
      this.$store.commit("set_snackbar", "Coming Soon!");
    },
    async initialize() {
      this.loading = true;
      const { data } = await this.$apollo.query({
        query: gql`
          query BootCampEvents {
            boot_camp_events {
              id
              event_date
              capacity
              boot_camp_attendees_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        `,
      });

      this.event_data = data.boot_camp_events;

      this.event_data.forEach((item) => {
        item.agents_cnt = item.boot_camp_attendees_aggregate.aggregate.count;
      });

      this.loading = false;
    },
    formatDate(date) {
      return dayjs(date).format("MM/DD/YYYY");
    },
  },
};
</script>
